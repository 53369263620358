@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #f97316;
  border-radius: 12px;
}

body,html,#root {
  margin: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-family: "Hanken Grotesk", sans-serif;
}

h1,h2,h3,h5,h6 {
  font-family: "Poppins", sans-serif;
}

@layer base {
  b {
    @apply text-orange-500;
  }
}